import React from "react"

//import { Link } from "gatsby"
import Layout from "../components/layout"
import LogoImage from "../components/logoimage"
import Seo from "../components/seo"
import {Hero, Columns, Container, Heading, Navbar, Content, Button} from 'react-bulma-components'
import Socialmedia from "../components/socialmedia"
import { OutboundLink } from 'gatsby-plugin-gtag'
const ThanksPage = () => {
  
  return (  
    <Layout>
      <Seo title="Greenheat Thank You!" />
      <Hero size="fullheight" color="primary">
      <Hero.Header renderAs="header">
        <Container>
        <Navbar>
          <Navbar.Brand>
            <Navbar.Item href='/'>
              <LogoImage type="white"/>
            </Navbar.Item>
          </Navbar.Brand>
        </Navbar>
        </Container>
      </Hero.Header>
      <Hero.Body>
        <Container>
        <Columns className="is-vcentered">
          <Columns.Column size="two-thirds">
          
          </Columns.Column>  
          <Columns.Column>
            <Heading>
              Thanks!
              
              </Heading>
              <Content>
              We'll notify you about product updates.
              </Content>
              <Content>Meanwhile, you can join our social media channels for updates</Content>
              <Socialmedia/>
              <Content>
              And please fill the survey and <a href="https://forms.gle/u2DxGPFXRv89fWAe7">answer key important questions</a> so we can suggest the best solution for you.
              </Content>
              <Content>
              <OutboundLink href='https://forms.gle/u2DxGPFXRv89fWAe7'><Button>Take a survey</Button></OutboundLink>
              </Content>
          </Columns.Column>
        </Columns>
        </Container>
      </Hero.Body>
      </Hero>
      
    </Layout>
  )
}

export default ThanksPage
